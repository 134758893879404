<template>
    <section class="bg-dark py-5 dark-web-section">
        <div class="container h-100">
            <div class="row h-100 align-items-center">
                <div class="col-12">
                    <h2 class="fw-light m-0 text-white">Seu e-mail pode estar exposto na <strong class="fw-bold">Dark Web!</strong></h2>
                    <p class="fw-light m-0 text-white"><strong class="fw-bold">Não espere</strong> ser vítima de golpes e fraudes.</p>
                    <Button
                        tag="darkweb"
                        type="submit"
                        class="btn btn-lg text-white rounded-pill verify-now"
                        @click="modalController.open">Verificar agora
                    </Button>
                </div>
            </div>
        </div>

        <div class="modal" id="modal-dark-web-hp-landingpage" tabindex="-1" role="dialog" aria-hidden="true" data-bs-backdrop="static">
            <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
                <div class="modal-content position-relative">
                    <button class="btn position-absolute fs-4 close-modal-button border-0" @click="modalController.close" type="button">
                        <i class="fas fa-times"></i>
                    </button>
                    <div class="modal-body p-0">
                        <IDScan in-modal />
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script setup>
    import IDScan from "@/components/Homepages/mcafeeDarkWeb/IdScan.vue"
    import { Modal } from "bootstrap"
    import { onMounted, ref } from "vue"
    import Button from "@/components/Form/Button/Button.vue";

    const modal = ref(null)

    const modalController = ref({
        open: () => {
            modal.value.show()
        },
        close: () => {
            modal.value.hide()
        }
    })

    onMounted(() => {
        modal.value = new Modal(document.querySelector('#modal-dark-web-hp-landingpage'))
    })
</script>

<style lang="scss" scoped>
    section.dark-web-section {
        background-image: url('./../../assets/img/dark-web-banner.png');
        background-position: center center;
        background-size: cover;
        height: 24.75rem;

        p {
            margin: 2rem 0 !important;
        }

        button.verify-now {
            $baseColor: #FF1C1C;

            background-color: $baseColor;
            border-color: $baseColor;
            font-size: 1rem;

            &:hover, &:active {
                background-color: darken($baseColor, 5%) !important;
                border-color: darken($baseColor, 5%) !important;
            }
        }
    }

    .modal-content {
        border-radius: 30px;
        border: none !important;
    }

    .close-modal-button {
        top: 1rem;
        right: 1rem;
        z-index: 10;

        &:hover, &:active {
            color: #000000;
            border: none;
        }
    }
</style>
