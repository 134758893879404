<template>
  <div class="row w-100 d-flex flex-column">
    <div class="products-carousel-container mx-auto p-0">
      <div class="overflow-x-auto d-sm-flex d-block align-items-strech gap-3">
        <McafeeProduct
          v-for="(product, key) in getProducts"
          :key="key"
          :product="product"
          :add-product="addProduct"
          :is-selected="checkProductSelected(product)"
          :open-cart="openCart"
        />
      </div>
    </div>

    <div class="col col-12 mt-1">
      <p class="m-0 text-center small text-dark">* O preço mostrado corresponde ao primeiro ano. Consulte abaixo os detalhes da oferta.</p>
    </div>
  </div>
</template>

<script setup>
  import { computed, defineProps } from 'vue';
  import { useStore } from 'vuex';

  import McafeeProduct from "@/components/Product/McafeeProduct";

  // * Props
  const props = defineProps({
    products: {
      type: Array,
      default: () => []
    },
    addProduct: {
      type: Function,
      default: () => {}
    },
    openCart: {
      type: Function,
      default: () => {}
    },
  });

  // * Setup
  const store = useStore();

  // * Computed
  const hasProductInCartComputed = computed(() => store.getters['cart/hasProductInCart']);
  const getProducts = computed(() => props.products.length ? props.products : []);

  // * Methods
  const checkProductSelected = product => hasProductInCartComputed.value(product?.product);
</script>

<style lang="scss" scoped>
  .products-carousel-container {
    width: fit-content;
  }

  .cart-button {
    background-color: #C01818;
    transition: all .2s;

    &:hover {
      background-color: #f03b3b;
    }
  }

  .overflow-x-auto {
    -webkit-overflow-scrolling: touch;
  }
</style>
