<template>
    <div class="card bg-white rounded-4 me-3" :class="{'produtc-emphasis': getProduct.offerInFocus}">
        <div :class="{'card-selected': isSelected}" class="rounded-4 card-body text-center">
            <div class="d-flex justify-content-center mb-3">
                <img src="@/assets/icons/devices.svg" alt="">
            </div>
            <h5 class="title">{{ getProductName }}</h5>
            <div>
                <p class="sub-title">{{ getProduct.description }}</p>
            </div>
            <div class="price">
                <strong>{{ currencyBRFormat(promotion.price.price) }}*</strong>
            </div>
            <div class="price-discount fw-bold">
                <strong>{{ currencyBRFormat(getProduct.price.price) }}</strong> <span>economize {{ currencyBRFormat(getProduct.price.price - promotion.price.price) }}</span>
            </div>

            <div class="mt-3 d-flex align-items-center justify-content-center">
                <Button v-if="isSelected" :disableTracking="true" :tag="getProductName" class="w-100" pill variant="light" type="button">
                    Selecionado
                </Button>

                <Button v-else class="w-100" type="button" :tag="getProductName" @click="addProductEvent()">
                    Comprar agora
                </Button>
            </div>
            <div class="mt-2">
                12 x {{ currencyBRFormat(promotion.price.price / 12) }}
            </div>
            <div class="destaque">Melhor oferta!</div>
        </div>
    </div>
</template>

<script setup>
import { ref, computed, defineProps } from 'vue';
import MoneyService from "@/services/numbers/money";
import Button from "@/components/Form/Button/Button";

const props = defineProps({
    product: {
        type: Object,
        default: () => ({})
    },
    isSelected: {
        type: Boolean,
        default: false
    },
    addProduct: {
        type: Function,
        default: () => {}
    },
    openCart: {
        type: Function,
        default: () => {}
    },
});

const selectedMessage = ref(0);
const selectedPointsIndex = ref(0);
const selectedPointAdditional = ref(null);

const getProduct = computed(() => props.product?.product || {});
const getPromotions = computed(() => getProduct.value?.promotions || []);
const currencyBRFormat = (value) => MoneyService.currencyBRFormat(value || 0);
const promotion = computed(() => getPromotions.value[selectedMessage.value] || {});
const getProductName = computed(() => getProduct.value?.name || "");

const addProductEvent = () => {
    const promo = promotion.value || getPromotions.value[0] || null;
    const productResidentialPointAdditionalAmount = selectedPointsIndex.value;
    const productResidentialPointAdditionalType = selectedPointAdditional.value || null;
    props.addProduct({
        product: getProduct.value,
        promotion: promo,
        productResidentialPointAdditionalAmount,
        productResidentialPointAdditionalType
    });
    props.openCart();
};

</script>

<style lang="scss" scoped>
.card {
    max-width: 285px !important;
    min-width: 245px !important;
    border: 1px solid #94999E;
    border-radius: 8px!important;
    background-color: #FAFAFA !important;
    margin: 1rem 1rem 1rem 0;
    position: relative;

    .title {
        font-size: 1.125rem;
    }

    .sub-title {
        font-size: 1rem;
    }

    .price {
        font-size: 2.25rem;
    }
    .price-discount {
        strong {
            text-decoration: line-through;
        }
        span {
            color: $redSecondary;
        }
    }
    button{
        border-radius: 8px!important;
        border: 1px solid #94999E;
        font-size: 1rem;
        font-weight: 600;
        color: $black !important;
        background-color: transparent;
        padding: 0.44rem;
    }
    .destaque {
        display: none;
    }
    &.produtc-emphasis {
        background-color: $white !important;
        box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.25);
        overflow: hidden;
        button {
            background-color: $redSecondary;
            color: $white !important;
        }
        .destaque {
            position: absolute;
            z-index: 1;
            display: block;
            top: 34px;
            left: -65px;
            background: $redSecondary;
            color: $white;
            transform: rotate(-45deg);
            padding: 4px 45px 4px 65px;
            font-size: 0.875rem;
        }
    }
}
</style>
