<template>
    <header class="row m-0 mt-3">
        <div class="d-flex col col-12 col-md-6 justify-content-center align-items-center order-last order-md-first">
            <Carousel />
        </div>
        <div class="col col-12 col-md-6 text-center d-flex flex-column justify-content-center align-items-center">
            <h1 class="fw-light m-0 mb-3" v-html="partnersText" />
            <McafeeProductsCatalog
                :products="products"
                :add-product="addProduct"
                :open-cart="openCart"
            />
            <Platforms htmlClass="mt-4 mb-2 text-center" />
            <Brands v-if="partner !== Partners.MASTERCARD" />
        </div>
    </header>
</template>

<script setup>
    import { defineProps, onMounted, ref, computed } from 'vue';
    import Carousel from "@/components/Carousel/HpLandingPageCarousel"
    import McafeeProductsCatalog from "@/components/Sale/McafeeProductsCatalog"
    import Platforms from "@/modules/landingPages/mcafee/components/Platforms"
    import Brands from "@/modules/landingPages/mcafee/components/Brands"
    import { useStore } from 'vuex'
    import Partners from "@/constants/partner/partner.constants"

    const partner = ref('');

    const store = useStore();

    const props = defineProps({
        products: {
            type: Array,
            required: true,
            default: () => []
        },
        addProduct: {
            type: Function,
            default: () => {}
        },
        openCart: {
            type: Function,
            default: () => {}
        },
    });

    const partnersToRender = {
        [Partners.HP]: '<b class="fw-bold">Parabéns</b> por adquirir um produto com qualidade <b class="fw-bold">HP</b>',
        [Partners.HPMCAFEE]: '<b class="fw-bold">Parabéns</b> por adquirir um produto com qualidade <b class="fw-bold">HP</b>',
        [Partners.MASTERCARD]: '<b class="fw-bold">Parabéns</b> por fazer parte do programa <b class="fw-bold">Mastercard Surpreenda!</b>',
        [Partners.SEMPRE_SEGURO]: '<b class="fw-bold">Milhares de clientes</b> compram, jogam e navegam online com segurança. <b class="fw-bold">Seja um deles!</b>',
        [Partners.RIGSTAR]: '<b class="fw-bold">Experimente</b> grátis por <b class="fw-bold">30 dias</b> o McAfee Total Protection'
    }

    const partnersText = computed(() => partnersToRender[partner.value] ?? '');

    onMounted(()=> {
        partner.value = store.getters.getPartner
    } )

</script>

<style lang="scss" scoped>
    .row .col:first-child {
        background-color: #F2F2F2;
        height: 720px;
        border-top-right-radius: 60px;
        border-bottom-right-radius: 60px;
    }
</style>
